<template>
  <CRow class="mt-2">
    <CCol sm="12">
      <CTabs :active-tab="activeTab" @update:activeTab="handleTab" >
        <CTab :title="$t('label.entryOrders')">
          <OrdenesIngreso :tap="activeTab" class="ml-1 mr-1"/>
        </CTab>
         <CTab :title="$t('label.shipmentOrders')">
          <OrdenesEmbarque :tap="activeTab" class="ml-1 mr-1"/>
        </CTab>
      </CTabs>
    </CCol>
  </CRow>
</template>

<script>
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import OrdenesIngreso from './vehicle-ordenes-ingreso-index';
import OrdenesEmbarque from './vehicle-ordenes-embarque-index';

//Funcion data() del ciclo.
function data() {
  return {
    isSubmit: false,
    isLoadingGeneral: false,
    activeTab: 2
  }
}

//Methods:
function handleTab(tab) {
  this.activeTab = tab;
}

export default {
  name: 'driver',
  components: {
    CustomTabs,
    CustomTab,
    OrdenesEmbarque,
    OrdenesIngreso,
  },
  props: {
    tabIndex: {
      type: Number,
      default: 0,
    },
  },
  data,
  methods: {
    handleTab,
  },
  computed: {},
  watch: {
    tabIndex: function(newVal, OldValue) {
      if(newVal == 1){
        this.activeTab = 0;
      }
      if(OldValue == 1) {
        this.activeTab = 2;
      }
    }
  }
}
</script>